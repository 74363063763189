import * as Helper from './RecurrenceHelper';
import moment from 'moment'
import { normalizeDateTime, normalizeDateFormValue } from 'src/helpers';

export interface RecurrenceRequest {
    RecurType?: number | null,
    Iterations?: number | null,
    RecurPattern: string | null ,
    DateTrigger?: Date,
    DateCompleted?: Date,
    HourInterval?: number | null,
    HoursTrigger?: number | null,
    HoursCompleted?: number | null,
    LockHours?: boolean,
    
}
export interface RecurrenceResponse {
    fldHoursTrigger?: number,
    fldDateTrigger?: string | null,
    fldIterations?: number,        
}
/* Calls functions to advance hour interval based triggers, date interval based triggers and decrements iterations
when conditions are met.
*/ 
export const CalculateNewRecurrenceTriggers = (recurParams: RecurrenceRequest): RecurrenceResponse => {
    // Set default values in case no conditions are met
    const result: RecurrenceResponse = {
        fldHoursTrigger:  -1,
        fldDateTrigger:  null,
        fldIterations : -1
    };
    const recurType = recurParams.RecurType || 0;
    const dateLastCompleted = recurParams.DateCompleted ? moment(recurParams.DateCompleted).toDate() :   new Date();
    let iterations = recurParams.Iterations || -1;
    // Decrement iterations if needed.
    if (iterations > 0) {
        iterations -= 1;
        result.fldIterations = iterations;
        if (iterations === 0){
            result.fldDateTrigger = null;
        }
    }
    if (recurType < 100 && recurParams.HourInterval && recurParams.HourInterval > 0 && (iterations == -1 || iterations > 0)) {
        // Advance hours trigger, if recurrence has an hours interval component
        if (recurParams.LockHours)
        {
            // then return the last Hour trigger + the interval
            result.fldHoursTrigger = recurParams.HourInterval + (recurParams.HoursTrigger || 0);
        }
        else
        {
            result.fldHoursTrigger = recurParams.HourInterval + (recurParams?.HoursCompleted || 0);
        }
    }
    // Get next date recurrence.
    if (recurType < 100 && recurType > 0 && (iterations == -1 || iterations > 0))
    {
        const recurDateTrigger = getRecurrenceDate(recurType, recurParams.RecurPattern, 
            dateLastCompleted, recurParams.DateTrigger);
            result.fldDateTrigger = normalizeDateTime(recurDateTrigger);
    }
    // else
    // {
    //     // changed
    //     // result.fldDateTrigger = normalizeDateTime(new Date());
    // }

    console.log('Input Recur Params', recurParams, 'Calculated Recurrence Trigger', result);
    return result;
}/// </summary>
        
        
/*
    Returns the next date based on recurrencePattern, recurrenceType and startDate paramenters.
*/
export function getRecurrenceDate ( recurrenceType: number, 
    recurrencePattern: string | null, 
    startDate?: Date,
    lastTriggerDate?: Date ) : Date | undefined {
    
    // console.log(`startDate as sent in: ${startDate}`);
    // console.log(`recurParams DateTrigger to start: ${lastTriggerDate}`);
    // console.log(`recurType: ${recurrenceType}`);

    // initiatialize local variables
    let today: Date = new Date();
    if (startDate == undefined) startDate = today;
    let recurrenceToAdd: number = 0;
    let nextDue: Date | Date[] = new Date();
    const lastTrigger : Date = lastTriggerDate || new Date();
    if (!recurrencePattern) {
        return nextDue;
    }

    console.log(`startDate after initialization: ${startDate}`);

    try {
        switch (recurrenceType) {
            // Calculates nextDue date by adding x number of weeks passed through recurrencePatern
            case 47:
                recurrenceToAdd = parseFloat(recurrencePattern);
                startDate.setUTCDate(startDate.getUTCDate() + (recurrenceToAdd * 7));
                nextDue = startDate;
                break;
            // Calculates the next recurrence based on <recurrencePatern> and <startDate> arguments.
            // <recurrencePatten> example: '4, 0, 1, 2' - Every 4 weeks Sunday, Monday and Tuesday.
            case 48:
                nextDue = Helper.getWeeklyEveryXWeeksOnYDays(recurrencePattern, startDate, lastTrigger);
                break;
            // Calculates nextDue date by adding x number of years passed through recurrencePatern.
            case 49:
                recurrenceToAdd = parseInt(recurrencePattern);
                startDate.setUTCFullYear(startDate.getUTCFullYear() + recurrenceToAdd);
                nextDue = startDate;
                break;
            // Calculates the next recurrence based on <recurrencePatern> and <startDate> arguments.
            // <recurrencePatten> example: '1, 1, 8' - First Monday of September.
            case 50:
                nextDue = Helper.getYearlyTheOrdinalDayOfMonth(recurrencePattern,  nextDue, lastTrigger);
                break;
            // Calculates the next recurrence based on <recurrencePatern> and <startDate> arguments.
            // <recurrencePatten> example: '1, 1' - Yearly every February 1st.
            case 51:
                nextDue = Helper.getYearlyEachXMonthYDay(recurrencePattern,  nextDue, lastTrigger);
                break;
            // Calculates nextDue date by adding x number of months passed through recurrencePatern.
            case 54:
                recurrenceToAdd = parseInt(recurrencePattern);
                startDate.setUTCMonth(startDate.getUTCMonth() + recurrenceToAdd);
                nextDue = startDate;
                break;
            // Calculates the next recurrence based on <recurrencePatern> and <startDate> arguments.
            // <recurrencePatten> example: '1, 1, 14' - First Monday every 14 months.
            case 55:
                nextDue = Helper.getMonthlyTheOrdinalDayEveryXMonths(recurrencePattern, startDate, today, nextDue);
                break;
            // Calculates the next recurrence based on <recurrencePatern> and <startDate> arguments.
            // <recurrencePatten> example: '4, 3' - Day 4 of every 3 months. 
            case 56:
                // in this case startDate should come from logEntry.logDate
                nextDue = Helper.getMonthlyDayXOfEveryYMonths(recurrencePattern,   nextDue, lastTrigger);
                break;
            // X days after date completed (simple pattern)    
            case 62:
                nextDue = Helper.getDailySimple(recurrencePattern, startDate); 
                break;
            // Calculates 
            case 63:
                nextDue = Helper.getDailyEveryWeekday(recurrencePattern, startDate, today, nextDue);
                break;
            // Calculates nextDue date by adding x number of days passed through recurrencePatern.
            case 64:
                nextDue = Helper.getDailySimple(recurrencePattern, startDate);
                break;
            default:
                break;
        }
        return nextDue;
    } catch(error) {
        throw error;
    }
}

// console.log(getRecurrenceDate(63, '2, 3, 4, 5, 6', new Date('2022-10-08')));